@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'AmazonEmber_Rg';
  src: url( './fonts/AmazonEmber_Rg.ttf' ) format( 'truetype' );
}

@font-face {
  font-family: 'AmazonEmber_Medium';
  src: url( './fonts/Amazon-Ember-Medium.ttf' ) format( 'truetype' );
}

@font-face {
  font-family: 'AmazonEmber_Bd';
  src: url( './fonts/AmazonEmber_Bd.ttf' ) format( 'truetype' );
}

body {
  font-family: 'AmazonEmber_Rg';
  box-sizing: border-box;
}
select > option {
  font-family: 'AmazonEmber_Rg' !important;
}

::-webkit-scrollbar {
  width: 10px;
}
::-webkit-scrollbar-track {
  background: #f1f1f1;
}
::-webkit-scrollbar-thumb {
  background: #888;
}
::-webkit-scrollbar-thumb:hover {
  background: #555;
}